export default {
    "Coal": "28, 19, 0",
    "Oil": "71, 0, 99",
    "Gas": "87, 87, 87",
    "Nuclear": "230, 230, 23",
    "Hydropower": "0, 8, 250",
    "Solar": "235, 175, 26",
    "Wind": "117, 235, 26",
    "Electricity": "245, 255, 46",
    "Heat": "255, 93, 71"
}